<template>
  <el-select
    v-model="value"
    class="base-layers-toggler"
    placeholder="Выберите подложку">
    <el-option
      v-for="layer in layers"
      :key="layer.name"
      :label="layer.label"
      :value="layer.name" />
  </el-select>
</template>

<script>
import { baseLayers } from '@/constants/mapbox'
export default {
  data () {
    return {
      layers: [...baseLayers],
      value: ''
    }
  },
  computed: {
    baseLayer () {
      return this.$store.state.initialState.baseLayer
    }
  },
  watch: {
    value (value) {
      this.$store.commit('SET', ['initialState.baseLayer', value])
      this.$store.commit('SYNC_STORAGE')
    },
    baseLayer () {
      this.value = this.baseLayer
    }
  },
  mounted () {
    this.value = this.baseLayer
  }
}
</script>

<style lang="scss">
.base-layers-toggler {
  background-color: transparent;
  border: none;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  align-items: center;
  width: 100% !important;

  .el-input__inner {
    border: 0 !important;
  }
}
</style>
