<template>
  <dl class="r-settings-list">
    <div
      v-for="setting in settings"
      :key="setting.id"
      class="r-settings-list__item">
      <dt class="r-settings-list__item--title">
        {{ setting.title }}
      </dt>
      <dd class="r-settings-list__item--core">
        <component :is="setting.component" />
      </dd>
    </div>
    <span class="settings-card__tmp">v1.01</span>
  </dl>
</template>

<script>
import themeToggler from '@/components/settings/theme-toggler'
import baseLayerToggler from '@/components/settings/baselayers-toggler'
import backToAppSelect from '@/components/settings/back-to-app-select'

export default {
  components: {
    themeToggler,
    baseLayerToggler,
    backToAppSelect
  },
  props: {
    settings: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="scss">
.r-settings-list {
  width: 100%;
  display: grid;
  justify-content: stretch;
  grid-auto-flow: row;
  grid-gap: 1rem;

  &__item {
    display: grid;
    grid-auto-flow: row;
    padding-bottom: 1rem;
    grid-gap: 0.5rem;

    &--core {
      margin: 0;
    }

    &--title {
      font-weight: 400;
      line-height: 1.5;
      font-size: 12px;
      color: var(--text_secondary);
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--dividers_low_contrast);
    }
  }
}

.settings-card__tmp {
  position: absolute;
  bottom: 50px;
  left: 0.5rem;
  color: var(--text_tertiary);
}
</style>
