<template>
  <r-tabs v-model="theme">
    <r-tab
      v-for="t in themes"
      :id="t"
      :key="t"
      :icon="t"
      :name="t === 'dark' ? 'Тёмная' : 'Светлая'" />
  </r-tabs>
</template>

<script>
import { toggleBodyTheme } from '@/utils'

export default {
  data () {
    return {
      theme: 'dark',
      themes: ['dark', 'light']
    }
  },
  computed: {
    darkTheme () {
      return this.$store.state.darkTheme
    }
  },
  watch: {
    theme (theme) {
      this.changeTheme(theme)
    },
    darkTheme (v) {
      this.theme = v ? 'dark' : 'light'
    }
  },
  mounted () {
    if (!this.darkTheme) this.theme = 'light'
  },
  methods: {
    changeTheme (theme) {
      this.$store.commit('SET', ['darkTheme', theme === 'dark'])
      this.$store.commit('SET', ['initialState.baseLayer', theme])
      this.$store.commit('SYNC_STORAGE')
      toggleBodyTheme()
    }
  }
}
</script>

<style lang="scss">
.el-radio-group.theme-toggler {
  display: flex;

  .el-radio-button {
    flex: 1;

    .el-radio-button__inner {
      height: 36px !important;
      display: grid;
      align-items: center;
      justify-content: center;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      padding: 0;
      background-color: var(--bg_containers);
      border: 0;
      box-shadow: none !important;
      font-size: 14px;
      color: var(--text_primary);
      font-weight: 400;
    }

    &.is-active {
      .el-radio-button__inner {
        background-color: var(--accent_selected) !important;
      }
    }
  }
}
</style>
