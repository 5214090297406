<template>
  <div class="back-to-app-select">
    <router-link to="/">
      <r-icon
        name="home"
        caption
        :size="18" />
      <r-text> Вернуться к выбору модуля </r-text>
    </router-link>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.back-to-app-select {
  a {
    display: flex;
    align-items: center;

    .r-icon {
      margin-right: 8px;
    }
  }
}
</style>
